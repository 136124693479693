import React from "react"
import { Link } from "gatsby"
import { withNamespaces } from "react-i18next";

import Layout from "../components/layout"
import SEO from "../components/seo"
import NavBar from "../components/navbar"
import Footer from "../components/footer"
import Loader from "../components/loader"

import {Helmet} from "react-helmet";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]',{
		speed: 1000,
		speedAsDuration: true,
		easing: 'easeInOutCubic'
	})
}


class Contact extends React.Component {

    state = {
    }


    componentDidMount() {
        window.location.replace("https://www.atlantide.io/");
    }    

    render() {
        const {t, i18n} = this.props;

        return (
            <Layout>
                <SEO title="Contact" />  
                <Loader />
                
                <div id="" className="hero is-relative faq">
                    <NavBar siteTitle="Atlantide IO" foceSolide/>
                    <section id="contact" className="section is-medium">
                        <div className="container">
                            
                            <div className="section-title-wrapper">
                                <div className="bg-number"><i className="material-icons">email</i></div>
                                <h2 className="section-title-landing has-text-centered dark-text">{t('title')}</h2>
                            </div>

                            <form className="validate-form" form-name="contact" name="contact" method="POST" data-netlify-honeypot="bot-field" data-netlify="true" action="/contact_success">
                                <input type="hidden" name="bot-field" />
                                <input type="hidden" name="form-name" value="contact" />
                                
                                <p className="formRow">
                                    <input className="validate-input" type="text" name="name" placeholder={t("inpute_name")}/>
                                </p>

                                <p className="formRow">
                                    <input className="validate-input"  type="email" name="email" placeholder={t("inpute_mail")}/>
                                </p>

                                <p className="formRow">
                                    <input className="validate-input" type="city" name="city" placeholder={t("inpute_city")}/>
                                </p>

                                <p className="">
                                    <label for="sujet[]">{t("input_sujet.title")}</label>
                                    <select name="sujet[]" className="select-css">
                                        <option value="question_app">{t("input_sujet.question_app")}</option>
                                        <option value="question_mission">{t("input_sujet.question_mission")}</option>
                                        <option value="event_teambuilding">{t("input_sujet.question_event")}</option>
                                        <option value="event_school">{t("input_sujet.question_event_school")}</option>
                                        <option value="lbd">{t("input_sujet.question_home")}</option>
                                        <option value="bug_app">{t("input_sujet.question_bug_app")}</option>
                                        <option value="bug_web">{t("input_sujet.question_bug_mission")}</option>
                                        <option value="other">{t("input_sujet.question_other")}</option>
                                    </select>
                                </p>

                                <p>
                                    <textarea className="validate-input"  rows="4" name="message" placeholder={t("inpute_message")}></textarea>
                                </p>
                                <p>
                                    <button className="contact2-form-btn" type="submit">
                                        {t("send")}
                                    </button>
                                </p>

                            </form>

                        </div>
                    </section>
                </div>
    
                <Footer />
            </Layout>
        )

    } 
}

export default withNamespaces("Contact")(Contact);
